import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/privacy/readyfive-subprocessors.md"

export default function ReadyFiveSubprocessorsPage() {
  return (
    <LegalLayout
      description="All the third-party subprocessors that we use to run Ready Five."
      source={source}
      title="Ready Five Subprocessors"
    />
  )
}
